<template>
  <div class="body">
    <p class="content">
      <canvas id="myCanvas" ref="wheelbg"></canvas>
      <img :src="imgUrl" class="img" id="imgs"  ref="img"/>
      <!-- <img src="../fukaSms/a.png" class="img" id="imgs"  ref="img"/> -->
      <span class="button">
        <span class="get" @click="toShop">领取祝福</span>
        <span class="help" @click=" popShow=true ">使用指南</span>
      </span>
      <div class="popCont" v-if="popShow">
        <b>使用指南 </b>
       <span class="popContSpan">
          <span>1.点击 "领取祝福" 进入福利商城；</span> <br>
          <span>2.点击 "我的" 注册并完成登陆；</span> <br>
          <span>3.点击 "兑换" 按钮输入短信中的卡密完成充值；</span><br>
          <span>4.进入商城首页挑选心仪祝福下单。</span> 
       </span>
       <span class="close" @click=" popShow=false ">我知道了</span>
      </div>
    </p>
    <div class="pop" v-if="popShow"></div>
  </div>
</template>
<script>
import { getfukaCode } from "@/services/user.js";
export default {
   data() {
    return { 
     popShow:false,
     imgUrl:'',
     bid:'05f210cdeda893a8c11c270aa919e1f1',
    };
  },
  methods: {
      async getList() {
       try{
         let data = await getfukaCode(this.$route.params.id)
         console.log(data, 2222)
         if(data.code === 0){
           this.imgUrl=data.data.yun_pic;
           this.bid=data.data.secret_id;
         }
       }catch(err){
         throw err;
       }
    },
    toShop(){
      // console.log(`${window.location.host}/?bid=${this.bid}#/home`)
       location.href=`http://${window.location.host}/?bid=${this.bid}#/home`
    },
    _setWheelBgtcMainTonal(canvas, img){
        canvas.width = img.width
        canvas.height = img.height
        let context = canvas.getContext('2d')

        context.drawImage(img, 0, 0)

        // 获取像素数据
        let data = context.getImageData(0, 0, img.width, img.height).data
        console.log(data);
        let r = 0,
            g = 0,
            b = 0
        // 取所有像素的平均值
        for (let row = 0; row < img.height; row++) {
            for (let col = 0; col < img.width; col++) {
                r += data[(img.width * row + col) * 4]
                g += data[(img.width * row + col) * 4 + 1]
                b += data[(img.width * row + col) * 4 + 2]
            }
        }
        // 求取平均值
        r /= img.width * img.height
        g /= img.width * img.height
        b /= img.width * img.height
        console.log(r,g,b)
        // 将最终的值取整
        r = Math.round(r)
        g = Math.round(g)
        b = Math.round(b)
        
        //rgb转16进制 位运算
        const color = ((r << 16) | (g << 8) | b).toString(16)
        console.log(color,787878)
        return color
    }
  },
  created(){
    this.getList()
    //  this._setWheelBgtcMainTonal(canvas, img)
    // console.log(this.$route.params.id)
  },
  mounted(){
    //  const canvas = this.$refs.wheelbg
    //  const img = this.$refs.img
    //  const ctx=canvas.getContext("2d");
    //  ctx.drawImage(img,10,10);
    //  this._setWheelBgtcMainTonal(canvas, img)
    //  console.log(this.$refs.img)
  }
};
</script>

<style>
.body {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}
.content {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute
  /* background: #f0f; */
}
.img {
  width: 100%;
  height: 100%;
  /* margin-left: 5%; */
}
.button {
  width: 100%;
  float: left;
  /* margin-left: 1%; */
  margin-top: -0.5%;
  /* height: 1.1rem;
  margin-top: 0.2rem; */
  /* background: chartreuse; */
}
.get {
  width: 33%;
  position: fixed;
  z-index: 99;
  height: 0.7rem;
  left: 10%;
  line-height: 0.7rem;
  font-size: 0.34rem;
  background:rgba(0,0,0,0.65);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
  border-radius: 0.6rem;
  bottom: 6%;
  text-align: center;
  color: #fff;
}
.help {
  width: 33%;
  position: fixed;
  right: 10%;
  z-index: 99;
  bottom:6%;
  height: 0.7rem;
  line-height: 0.7rem;
  font-size: 0.34rem;
   background:rgba(0,0,0,0.65);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
  border-radius: 0.6rem;
  text-align: center;
  color: #fff;
}
.pop{
  position: fixed;
  width:100%;
  height:100%;
  background:#000;
  opacity: 0.5;
}
.popCont{
  position: fixed;
  width:80%;
  height:5.5rem;
  background:#fff;
  z-index: 9;
  border-radius:0.15rem;
}
.popContSpan{
    width: 90%;
    height: 65%;
    background: #fff;
    float: left;
    /* margin-top: 12%; */
    margin-left: 5%;
    font-size: 0.31rem
}
.popContSpan span{
    float: left;
    word-wrap: break-word;
    word-break: normal;
    width: 90%;
    margin-left:9%;
    line-height: 1.8
}
.popCont b{
  width:100%;
  float: left;
  text-align: center;
  height:11%;
  margin-top: 3%;
  font-size:0.4rem;
}
.close{
  width: 70%;
  float: left;
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.4rem;
  margin-left:15%;
   background:rgba(0,0,0,0.65);
  /* box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4); */
  border-radius: 0.6rem;
  text-align: center;
  color: #fff;
}
.close:active{
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.4);
}
#myCanvas{
  display: none;
}
</style>
