var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body" }, [
    _c("p", { staticClass: "content" }, [
      _c("canvas", { ref: "wheelbg", attrs: { id: "myCanvas" } }),
      _c("img", {
        ref: "img",
        staticClass: "img",
        attrs: { src: _vm.imgUrl, id: "imgs" },
      }),
      _c("span", { staticClass: "button" }, [
        _c("span", { staticClass: "get", on: { click: _vm.toShop } }, [
          _vm._v("领取祝福"),
        ]),
        _c(
          "span",
          {
            staticClass: "help",
            on: {
              click: function ($event) {
                _vm.popShow = true
              },
            },
          },
          [_vm._v("使用指南")]
        ),
      ]),
    ]),
    _vm.popShow
      ? _c("div", { staticClass: "popCont" }, [
          _c("b", [_vm._v("使用指南 ")]),
          _vm._m(0),
          _c(
            "span",
            {
              staticClass: "close",
              on: {
                click: function ($event) {
                  _vm.popShow = false
                },
              },
            },
            [_vm._v("我知道了")]
          ),
        ])
      : _vm._e(),
    _c("p"),
    _vm.popShow ? _c("div", { staticClass: "pop" }) : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "popContSpan" }, [
      _c("span", [_vm._v('1.点击 "领取祝福" 进入福利商城；')]),
      _c("br"),
      _c("span", [_vm._v('2.点击 "我的" 注册并完成登陆；')]),
      _c("br"),
      _c("span", [_vm._v('3.点击 "兑换" 按钮输入短信中的卡密完成充值；')]),
      _c("br"),
      _c("span", [_vm._v("4.进入商城首页挑选心仪祝福下单。")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }